import Head from "next/head";
import { FC } from "react";

type Props = {
  title?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
  twitterCard?: TwitterCard;
};

export type TwitterCard = {
  cardType: "summary" | "summary_large_image";
  creator: string;
  ogImage: string;
};

function determineTwitterCardSize(cardType: TwitterCard["cardType"]) {
  switch (cardType) {
    case "summary_large_image":
      return {
        width: 1200,
        height: 630,
      };
    case "summary":
      return {
        width: 1200,
        height: 1200,
      };
    default:
      return {
        width: 1200,
        height: 630,
      };
  }
}

const defaultProps: Props = {
  title: "FUSSY",
  ogDescription: "あなたの「好き」を応援するサービス",
  ogUrl: "",
  ogImage: `https://d1stpor4o81wuq.cloudfront.net/images/fussy-logo.png`,
};

const PageHead: FC<Props> = ({
  title,
  ogDescription,
  ogUrl,
  twitterCard,
  ogImage,
}) => {
  const fullOgUrl = `https://www.fussy.fun` + ogUrl;
  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={ogDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:site_name" content="FUSSY" />
      <meta property="og:url" content={fullOgUrl} />
      <meta property="og:locale" content="ja_JP" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property={"twitter:title"} content={title} />
      {twitterCard ? (
        <>
          <meta property="twitter:card" content={twitterCard.cardType} />
          <meta property="twitter:creator" content={twitterCard.creator} />
          <meta
            property="twitter:image:height"
            content={determineTwitterCardSize(
              twitterCard.cardType
            ).height.toString()}
          />
          <meta
            property="twitter:image:width"
            content={determineTwitterCardSize(
              twitterCard.cardType
            ).width.toString()}
          />
          <meta property="twitter:image" content={twitterCard.ogImage} />
          <meta property="twitter:description" content={ogDescription} />
          <meta property={"og:image"} content={twitterCard.ogImage} />
        </>
      ) : (
        <>
          <meta property="og:image" content={ogImage} />
          <meta property="twitter:card" content="summary" />
          <meta property="twitter:image" content={ogImage} />
          <meta property="twitter:image:src" content={ogImage} />
          <meta property="twitter:image:height" content="1200" />
          <meta property="twitter:image:width" content="1200" />
          <meta property="twitter:description" content={ogDescription} />
        </>
      )}
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

PageHead.defaultProps = defaultProps;

export default PageHead;
